<template>
  <app-card-code name="cancelarPoliza">
    <h3 class="ml-5">
      <v-icon
        class="mr-1"
        color="primary"
        @click="volver_poliza"
      >
        {{ icons.mdiArrowLeftCircle }}
      </v-icon>
      Solicitud cancelación póliza - {{producto}}
    </h3>
    <v-col cols="12">
      <v-row
        class="mb-15 mt-5 ml-3 mr-3 "
      >
        <!-- columna rut -->
        <v-col
          md="12"
          cols="12"
          class="header-inf-basica"
        >
          <h4
            class="header-inf-basica-text"
            style="color:#ffffff !important;"
          >
            Información básica del asegurado
          </h4>
        </v-col>
        <v-col
          md="4"
          cols="11"
          class="text-inf"
        >
          <span class="font-weight-medium">Rut :</span>
          <span
            v-mask="maskRut"
            class="text--secondary"
          >{{ rut }}</span>
        </v-col>
        <v-col
          md="8"
          cols="11"
          class="text-inf"
        >
          <span class="font-weight-medium me-2">Nombres y Apellidos:</span>
          <span class="text--secondary">{{ nombres }} {{ primerApellido }}</span>
        </v-col>
        <v-col
          md="4"
          cols="11"
          class="text-inf"
        >
          <span class="font-weight-medium me-2">Fecha Nacimiento :</span>
          <span class="text--secondary">{{ fechaNacimiento }}</span>
        </v-col>
        <v-col
          md="5"
          cols="11"
          class="text-inf"
        >
          <span class="font-weight-medium me-2">Correo:</span>
          <span class="text--secondary">{{ correo }}</span>
        </v-col>
        <v-col
          md="3"
          cols="11"
          class="text-inf"
        >
          <span class="font-weight-medium me-2">Teléfono:</span>
          <span class="text--secondary">{{ telefono }}</span>
        </v-col>
      </v-row>

      <hr>
      <h4>Motivo de cancelación</h4>
      <v-form
        v-if="!documentoActivo"
        ref="fomrCancelar"
      >
        <v-row class="mb-10 mt-5 ml-3 mr-3">
          <!-- columna rut -->
          <v-col
            md="9"
            cols="11"
            name="motivo"
          >
            <v-select
              name="inputMotivo"
              v-model="motivo"
              :items="optionsMotivo"
              label="Motivo"
              item-text="nombre"
              item-value="codigo"
              dense
              required
              :rules="[validators.required]"
            ></v-select>
          </v-col>
          <v-col
            md="3"
            cols="11"
          >
            <v-text-field
              name="fecha"
              v-model="fecha"
              dense
              label="Fecha"
              type="date"
              disabled="true"
            ></v-text-field>
          </v-col>

          <v-col
            md="9"
            cols="11"
          >
            <v-text-field
              name="usuarioCorreo"
              v-model="Usercorreo"
              label="Correo"
              dense
              required
              :rules="[validators.required, validators.emailValidator]"
              type="email"
            ></v-text-field>
          </v-col>
          <v-col
            md="3"
            cols="11"
          >
            <v-text-field
              name="usuarioTelefono"
              v-model="UserTelefono"
              dense
              label="Telefono"
              :rules="[validators.required]"
            ></v-text-field>
          </v-col>

          <v-col
            md="12"
            cols="11"
          >
            <v-textarea
              name="observacion"
              v-model="observacion"
              filled
              auto-grow
              label="Observación"
              rows="4"
              row-height="25"
              shaped
              :rules="[validators.required]"
            ></v-textarea>
          </v-col>
        </v-row>
        <div class="d-flex justify-end">
          <!-- <v-col
              md="12"
              cols="12"
            > -->
          <v-btn
            name="botonCancelacion"
            color="primary"
            class="me-2"
            :loading="loading"
            @click="cancelarPoliza()"
          >
            Solicitar Cancelar Póliza
          </v-btn>
        <!-- </v-col> -->
        </div>
      </v-form>

      <v-row
        v-if="documentoActivo"
        class="mb-10 mt-5"
      >
        <v-dialog
          v-model="isDialogVisible"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <span>
              <v-btn
                name="botonVerDocumento"
                class="col-md-12 col-12"
                color="primary"
                x-small
                text
                title="Documentos"
                v-bind="attrs"
                size="20"
                @click="verDocuemnto"
                v-on="on"
              >
                <v-icon>{{ icons.mdiBookMultiple }}</v-icon>
                FIRMA DOCUMENTO ANULACIÓN PÓLIZA
              </v-btn>
            </span>
            <span class="btn-selecion">
              <v-col
                md="1"
                cols="1"
              >
                <v-btn
                  v-if="!firma_valido && !firma_noValido"
                  color="error"
                  elevation="0"
                  fab
                  x-small
                >
                  <v-icon>{{ icons.mdiClose }}</v-icon>
                </v-btn>
                <v-btn
                  v-if="!firma_valido && firma_noValido"
                  color="info"
                  elevation="2"
                  fab
                  small
                  width="30px"
                  height="30px"
                  title="Firma de documento manual"
                >
                  <v-icon>{{ icons.mdiPencil }}</v-icon>
                </v-btn>
                <v-btn
                  v-if="firma_valido && !firma_noValido"
                  color="success"
                  elevation="0"
                  fab
                  x-small
                >
                  <v-icon>{{ icons.mdiCheck }}</v-icon>
                </v-btn>
              </v-col>
            </span>
          </template>
          <v-card name="modalDocumento">
            <v-toolbar
              dark
              color="primary"
            >
              <!-- primera modal-->
              <div class=" row col-12">
                <div class="col-md-2 col-2">
                  <v-btn
                    name="cerrarDocumento"
                    icon
                    dark
                    @click="cerrar_modal"
                  >
                    <v-icon>{{ icons.mdiClose }}</v-icon>
                  </v-btn>
                </div>
                <div class="col-md-7 col-7">
                  <v-toolbar-title class="tollbar-title ">
                    {{ tituloDocumento }}
                  </v-toolbar-title>
                </div>
                <div class="col-md-2 col-2 bonton-firma-icon">
                  <v-btn
                    name="botonfirmaDocumentoIcon"
                    v-if="!firmaActiva"
                    icon
                    dark
                    title="Firmar documento"
                    @click="firmarDocumento()"
                  >
                    <v-icon>{{ icons.mdiPencil }}</v-icon>
                  </v-btn>
                </div>
                <div class="col-md-3 col-3 bonton-firma">
                  <v-btn
                    name="botonfirmaDocumento"
                    v-if="!firmaActiva"
                    text
                    dark
                    title="Firmar documento"
                    @click="firmarDocumento()"
                  >
                    Firmar documento
                  </v-btn>
                </div>
              </div>

              <v-spacer></v-spacer>
            </v-toolbar>
            <v-list
              v-if="firmaActiva"
              three-line
              subheader
              class="PDFdoc"
              height="93vh"
            >
              <div class="row">
                <div class="col-md-12">
                  <object
                    id="iframe_firma"
                    ref="iframe_firma"
                    :data="urlFirma"
                    width="100%"
                    height="800%"
                    frameborder="0"
                    allowfullscreen
                  >
                    <!-- sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-modals" -->
                    <p> <a
                      href=""
                    >
                      Ocurrió un error invocando al firmador, por favor comuníquese con el administrador (1)
                    </a> </p>
                  </object>
                </div>
              </div>
            </v-list>
            <v-list
              v-if="!firmaActiva"
              three-line
              subheader
              class="PDFdoc"
              height="93vh"
            >
              <div class="row">
                <div class="col-md-12">
                  <object
                    width="100%"
                    height="800%"
                    type="application/pdf"
                    :data="documentoPDF"
                    frameborder="0"
                    allowfullscreen
                  >
                    <!-- sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-modals" -->
                    <p> <a :href="urlFirma">
                      Ocurrió un error invocando al firmador, por favor comuníquese con el administrador (2)
                    </a> </p>
                  </object>
                </div>
              </div>
            </v-list>
          </v-card>
        </v-dialog>
      </v-row>
    </v-col>
    <v-snackbar
      v-model="isFormSubmittedSnackbarVisible2"
      right
      top
      :color="colorSnackbar"
      timeout="2000"
    >
      {{ textSnackbar }}
      <!-- <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="isFormSubmittedSnackbarVisible2 = false"
        >
          <v-icon>{{ icons.mdiCloseThick }}</v-icon>
        </v-btn>
      </template> -->
    </v-snackbar>
  </app-card-code>
</template>

<script>
import { ref } from '@vue/composition-api'
import { required, emailValidator } from '@core/utils/validation'
import { mdiArrowLeftCircle, mdiBookMultiple, mdiClose, mdiCheck, mdiPencil } from '@mdi/js'
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'
import { polizastService } from '../../../../service/poliza.service'
import { maskRut, cleanMaskRut } from '@/helpers/rut'
import { FirmaService } from '../../../../service/firmador.services'

export default {
  components: {
    // DemoFormLayoutTabs,
    AppCardCode,
  },
  setup() {
    const rut = ref('')
    const nombres = ref('')
    const primerApellido = ref('')
    const fechaNacimiento = ref('')
    const correo = ref('')
    const Usercorreo = ref('')
    const telefono = ref('')
    const UserTelefono = ref('')
    const producto = ref('')
    const vigenciaDesde = ref('')
    const vigenciaHasta = ref('')
    const precioMensual = ref('')
    const poliza = ref('')
    const certificado = ref('')
    const documento = ref('')
    const propuestas = ref('')
    const sucursal = ref('')
    const vendedor = ref('')

    const motivo = ref({})
    const fecha = ref('')
    const observacion = ref('')

    const optionsMotivo = ref([])
    const isDialogVisible = ref(false)
    const tituloDocumento = ref('')
    const documentoActivo = ref(false)

    const isFormSubmittedSnackbarVisible2 = ref(false)
    const colorSnackbar = ref('')
    const textSnackbar = ref('')
    const fomrCancelar = ref(null)
    const loading = ref(false)

    const correoCliente64 = ref('')
    const idDocumento64 = ref('')
    const name64 = ref('')
    const propuesta64 = ref('')
    const rut64 = ref('')
    const urlPdf64 = ref('')
    const tipoCodigoAcepta64 = ref('')
    const documentoPre64 = ref('')
    const tipoDocumentoCodigo64 = ref('')
    const endoso64 = ref('')
    const urlFirma = ref('')
    const isDialogFirmadorVisible = ref(false)
    const firma_valido = ref(false)
    const firmaActiva = ref(false)
    const firma_noValido = ref(false)

    return {
      isDialogVisible,
      maskRut,
      firma_valido,

      rut,
      nombres,
      primerApellido,
      fechaNacimiento,
      correo,
      Usercorreo,
      telefono,
      UserTelefono,
      producto,
      vigenciaDesde,
      vigenciaHasta,
      precioMensual,
      poliza,
      certificado,
      documento,
      propuestas,
      sucursal,
      vendedor,

      motivo,
      fecha,
      observacion,

      optionsMotivo,
      tituloDocumento,
      documentoActivo,
      firma_noValido,

      icons: {
        mdiArrowLeftCircle,
        mdiBookMultiple,
        mdiClose,
        mdiCheck,
        mdiPencil,
      },
      isFormSubmittedSnackbarVisible2,
      colorSnackbar,
      textSnackbar,
      validators: {
        required,
        emailValidator,
      },
      fomrCancelar,
      loading,

      // variables firma
      correoCliente64,
      idDocumento64,
      name64,
      propuesta64,
      rut64,
      urlPdf64,
      urlFirma,
      tipoCodigoAcepta64,
      documentoPre64,
      tipoDocumentoCodigo64,
      endoso64,
    }
  },
  filters: {
    formatFecha(valor) {
      const dato = valor.split('-')

      return typeof dato === typeof [] ? `${dato[2]}-${dato[1]}-${dato[0]}` : 'Sin información'
    },
  },
  mounted() {
    this.$root.$on('Cancelcliente', data => {
      console.log(data, 'datattata')
    })
    console.log(this.$router.currentRoute.params.informacion, 'AAAAAAA')
    this.producto = this.$router.currentRoute.params.id.producto
    const fecha = new Date()

    // this.bucarRut(this.$router.currentRoute.params.rut, this.$router.currentRoute.params.id.propuesta)
    this.bucarRut(this.$router.currentRoute.params.informacion, this.$router.currentRoute.params.rut)
    this.fecha = `${fecha.toISOString().split('T')[0]}`
    window.addEventListener('message', event => {
      // IMPORTANT: check the origin of the data!
      if (event.origin === 'https://api.pvdigital.cl' && event.data.mensaje === 'firmaManual') {
        this.firmadorNoValido()
      }
      if (event.origin === 'https://api.pvdigital.cl' && event.data.mensaje === 'firmaDigital') {
        this.firmadorValido()
      }
    })
  },
  created() {
    this.motivoListaPoliza()
    console.log(this.$router.currentRoute.params, "titiit")
  },
  methods: {
    formatFecha(valor) {
      const dato = valor.split('-')

      return typeof dato === typeof [] ? `${dato[2]}-${dato[1]}-${dato[0]}` : 'Sin información'
    },
    volver_poliza() {
      this.$router.push({
        name: 'poliza_id',
        params: {
          rut: this.rut,
        },
      })
    },
    async bucarRut(itemRut, propuesta) {
      this.respuestaRut = false
      this.stipeActive = true

      // const rut = cleanMaskRut(itemRut)

      // await polizastService.getPolizaDetalle(rut, propuesta).then(resp => {
      //   if (resp.data.statusCode === 201 || resp.data.statusCode === 200) {
      const informacion = itemRut

      this.rut = propuesta
      this.nombres = informacion.nombres
      this.primerApellido = informacion.primerApellido
      this.segundoApellido = informacion.segundoApellido
      this.fechaNacimiento = this.formatFecha(informacion.fechaNacimiento)
      this.correo = informacion.correo
      this.Usercorreo = informacion.correo

      this.telefono = `(${informacion.telefono.substr(0, 3)}) - ${informacion.telefono.substr(
        3,
        3,
      )}-${informacion.telefono.substr(6, 4)}`
      this.UserTelefono = `(${informacion.telefono.substr(0, 3)}) - ${informacion.telefono.substr(
        3,
        3,
      )}-${informacion.telefono.substr(6, 4)}`
      this.calle = informacion.direccion.calle
      this.numero = informacion.direccion.numero
      this.departamento = informacion.direccion.departamento
      this.comuna = informacion.direccion.comuna
      this.localidad = informacion.direccion.localidad

      // }
      // })
    },
    async motivoListaPoliza() {
      this.optionsMotivo = []
      await polizastService.motivoLista().then(resp => {
        this.optionsMotivo = resp.data.data
      })
    },

    async cancelarPoliza() {
      const isFormValid = this.fomrCancelar.validate()
      if (!isFormValid) return
      this.loading = true
      const poliza = this.$router.currentRoute.params.id.propuesta
      const data = {
        rut: this.rut,
        propuesta: poliza,
        motivoOperacionCodigo: this.motivo,
        fechaSolicitud: this.fecha,
        observacion: this.observacion,
        estado: 'pendiente',
        solicitanteCodigo: JSON.parse(localStorage.getItem('userData')).codigo,
        sucursalCodigo: JSON.parse(localStorage.getItem('userSucursal')).codigo,
        correo: this.Usercorreo,
        telefono: this.UserTelefono,
      }
      await polizastService.anularPoliza(data).then(resp => {
        console.log(resp)
        if (resp.data.statusCode === 201 || resp.data.statusCode === 200) {
          this.documentoActivo = true
          this.documento = resp.data.data.documento
          const decodedStr = `data:application/pdf;base64,${this.documento.documento}`
          this.documentoPDF = decodedStr

          this.tituloDocumento = this.documento.titulo

          this.correoCliente64 = resp.data.data.variablesFirma.correoCliente64
          this.idDocumento64 = resp.data.data.variablesFirma.idDocumento64
          this.name64 = resp.data.data.variablesFirma.name64
          this.propuesta64 = resp.data.data.variablesFirma.propuesta64
          this.rut64 = resp.data.data.variablesFirma.rut64
          this.urlPdf64 = resp.data.data.variablesFirma.urlPdf64
          this.tipoCodigoAcepta64 = resp.data.data.variablesFirma.tipoCodigoAcepta64
          this.documentoPre64 = resp.data.data.variablesFirma.documentoPre64
          this.tipoDocumentoCodigo64 = resp.data.data.variablesFirma.tipoDocumentoCodigo64
          this.endoso64 = resp.data.data.variablesFirma.endoso64
          this.loading = false
        } else {
          this.documentoActivo = false
          this.isFormSubmittedSnackbarVisible2 = true
          this.textSnackbar = resp.data.message
          this.colorSnackbar = 'error'
          this.loading = false
        }
      })
    },

    verDocuemnto() {
      const decodedStr = `data:application/pdf;base64,${this.documento.documento}`
      this.documentoPDF = decodedStr

      this.tituloDocumento = this.documento.titulo
      if (this.firmaActiva == true) {
        this.firmaActiva = true
      } else {
        this.firmaActiva = false
      }
    },
    async firmarDocumento() {
      const data = {
        rut64: this.rut64,
        propuesta64: this.propuesta64,
        urlPdf64: this.urlPdf64,
        name64: this.name64,
        idDocumento64: this.idDocumento64,
        correoCliente64: this.correoCliente64,
        tipoCodigoAcepta64: this.tipoCodigoAcepta64,
        documentoPre64: this.documentoPre64,
        tipoDocumentoCodigo64: this.tipoDocumentoCodigo64,
        endoso64: this.endoso64,
      }

      /*
      await FirmaService.getFirma(data).then(res => {
        console.log(res.config, 'firma')
        //this.urlFirma = res.config.url
        this.firmaActiva = true
      })
      console.log(data)
      */
      this.urlFirma = `${process.env.VUE_APP_URL_API_FIRMA}/${data.rut64}/${data.propuesta64}/${data.urlPdf64}/${data.name64}/${data.idDocumento64}/${data.correoCliente64}/${data.endoso64}/${data.tipoDocumentoCodigo64}/${data.tipoCodigoAcepta64}`
      this.firmaActiva = true
    },
    firmadorValido() {
      this.firma_valido = true
      console.log('>>>> Validacion de firmador de doscumento desde el front >>>>>')
    },
    firmadorNoValido() {
      this.isFormSubmittedSnackbarVisible2 = true
      this.textSnackbar = 'Se requiere firma de documento manual. Por favor imprimir documento'
      this.colorSnackbar = 'info'
      this.loadingToken = false

      this.firma_valido = false
      this.firma_noValido = true
      this.firmaActiva = false
    },
    cerrar_modal() {
      this.isDialogVisible = false
    },
  },
}
</script>
<style scoped>
.header-inf-basica {
  background: linear-gradient(98deg, #67afed, var(--v-primary-base) 94%);
  color: white !important;
  font-weight: 300 !important;
  text-align: center;
  border-radius: 10px;
}
.header-inf-basica-text {
  color: white !important;
  font-weight: 300 !important;
}
.btn-selecion {
  margin-top: -15px !important;
}
.v-btn--fab.v-size--x-small {
  height: 20px !important;
  width: 20px !important;
}
.PDFdoc {
  overflow: hidden !important;
}
.bonton-firma-icon {
  display: none !important;
}
.bonton-firma {
  display: block !important;
}
@media (max-width: 600px) {
  .bonton-firma-icon {
    display: block !important;
  }
  .bonton-firma {
    display: none !important;
  }
}
@media (min-width: 800px) {
  .tollbar-title {
    margin-left: -95px;
  }
}
@media (min-width: 1000px) {
  .tollbar-title {
    margin-left: -115px;
  }
}
.text-inf {
  font-size: 14px;
}
</style>
